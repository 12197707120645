
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon
  },
  data() {
    return {
      pwdVisible: false,
      username: '',
      password: '',
      showError: false,
      showError2: false
    }
  },
  ionViewDidLeave() {
    this.pwdVisible = false
    this.username = ''
    this.password = ''
    this.showError = false
    this.showError2 = false
  },
  methods: {
    showPassword() {
      this.pwdVisible = !this.pwdVisible
      const elem = document.getElementById('changeviz') as HTMLInputElement
      if (this.pwdVisible) {
        elem.type = "text";
      } else {
        elem.type = "password";
      }
    },
    register() {
      this.$router.push('/signup')
    },
    login() {
      this.$store.dispatch('obtainToken', {username: this.username, password: this.password})
      .then( (resp: any) => {
        console.log(resp);
      })
      .catch((err: any) => console.log(err))
    }
  },
  computed: {
    loginRes() {
      return this.$store.state.accessResult
    }
  },
  watch: {
    loginRes: {
      handler: function(newVal) {
        console.log(newVal)
        if (newVal === 200) {
          this.showError = false;
          this.showError2 = false;
          this.$router.push('/hub')
        } else if (newVal == -2) {
          this.showError = false;
          this.showError2 = true;
        } else if (newVal != -1) {
          this.showError2 = false;
          this.showError = true;
        } 
      }
    }
  }
});
