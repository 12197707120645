<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col size="3"></ion-col>
          <ion-col size="6">
            <ion-row class="center-row mt-5">
              <img src="@/../public/assets/P4G-logo-rgb.png"/>
            </ion-row>
            <ion-row class="center-row mt-5">
              <div>
                <div class="card" style="height: auto; padding: 25px;" v-on:keyup.enter="login()">
                  <div class="input-bottom-2">
                    <input class="searchbar searchbar-secondary" placeholder="Username" v-model="username" type="text"/>
                  </div>
                  <div class="input-bottom-2 flex mt-2">
                    <input class="searchbar searchbar-secondary" id="changeviz" placeholder="Password" v-model="password" type="password"/>
                    <ion-icon class="clickable" style="margin-top: auto; font-size: 1.3em;" @click="showPassword()" v-if="!pwdVisible" name="visible"></ion-icon>
                    <ion-icon class="clickable" style="margin-top: auto; font-size: 1.3em;" @click="showPassword()" v-else name="not-visible"></ion-icon>
                  </div>
                </div>
                <div class="mt-2" style="min-height: 50px">
                  <label v-if="showError" style="color:red; display: block; text-align: center;"> Wrong Username or Password!</label>
                  <label v-if="showError2" style="color:red; display: block; text-align: center;"> Unauthorized!</label>
                </div>
                <div class="btns-div">
                  <button class="home-btn" @click="login()">Sign In</button>
                  <button class="home-btn mt-2" style="background: #070d30; color: white" @click="register()" color="primary">Sign Up</button>
                </div>
              </div>
            </ion-row>
            <ion-row class="center-row mt-10">
              <label>Developed By:</label>
              <img class="mt-1" id="aisentlogo" src="@/../public/assets/ais.png" />
            </ion-row>
          </ion-col>
          <ion-col size="3"></ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon
  },
  data() {
    return {
      pwdVisible: false,
      username: '',
      password: '',
      showError: false,
      showError2: false
    }
  },
  ionViewDidLeave() {
    this.pwdVisible = false
    this.username = ''
    this.password = ''
    this.showError = false
    this.showError2 = false
  },
  methods: {
    showPassword() {
      this.pwdVisible = !this.pwdVisible
      const elem = document.getElementById('changeviz') as HTMLInputElement
      if (this.pwdVisible) {
        elem.type = "text";
      } else {
        elem.type = "password";
      }
    },
    register() {
      this.$router.push('/signup')
    },
    login() {
      this.$store.dispatch('obtainToken', {username: this.username, password: this.password})
      .then( (resp: any) => {
        console.log(resp);
      })
      .catch((err: any) => console.log(err))
    }
  },
  computed: {
    loginRes() {
      return this.$store.state.accessResult
    }
  },
  watch: {
    loginRes: {
      handler: function(newVal) {
        console.log(newVal)
        if (newVal === 200) {
          this.showError = false;
          this.showError2 = false;
          this.$router.push('/hub')
        } else if (newVal == -2) {
          this.showError = false;
          this.showError2 = true;
        } else if (newVal != -1) {
          this.showError2 = false;
          this.showError = true;
        } 
      }
    }
  }
});
</script>

<style scoped>
.center-row {
  justify-content: center;
  display: grid;
  justify-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#aisentlogo {
  width: 20%;
  min-width: 10vh;
}

.btns-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>